.py-banner {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  margin: 5px 15px 15px 15px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  z-index: 2;
  min-height: 60px;
  overflow: hidden;
  position: relative;

  .angle {
    background: var(--accentColor) !important;
  }

  .btn {
    border-color: var(--accentColor);
    color: var(--accentColor);
    border-radius: 100px;
    &:hover {
      background: var(--accentColor) !important;
      color: #fff;
    }
  }

  .bg-shape,
  &-title {
    min-height: 60px;
    padding: 20px 40px 20px 20px;
    overflow: hidden;
    max-width: 40%;
    color: #fff;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    min-width: 20%;
    width: 20%;

    .angle {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      min-height: 100%;
      display: inline-block;
      z-index: -1;
      background: #136acd;
      /* fallback for old browsers */
      background: -webkit-linear-gradient(90deg, #093b96, #136acd);
      /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #093b96, #136acd);
      /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    .angle.front {
      transform: skew(-20deg, 0deg);
      right: 15%;
      min-width: 500px;
    }

    .angle.back {
      width: 60px;
      min-height: 80px;
      transform: rotate(-50deg);
      bottom: -38px;
      right: 15%;
      z-index: -2;
      opacity: 0.7;
    }
  }

  .bg-shape {
    min-width: 6%;

    &.right-shape {
      transform: rotate(180deg);
    }
  }

  &.card-bg {
    overflow: hidden;
    .bg-shape {
      display: none;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(7deg);
      width: calc(100% + 300px);
      height: calc(100% + 300px);
      z-index: -1;
    }

    &:before {
      //background-image: url('/../../assets/cards-bg.png');
      background-repeat: repeat;
      background-size: 200px;
      background-position: left 0 bottom 0;
      z-index: -2;
      animation: scrollCardBg 20s linear infinite;
    }

    &:after {
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 75%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    min-height: 60px;
    width: 100%;
    flex-wrap: wrap;
    padding: 15px;
    white-space: break-spaces;
    color: #1c252c;

    .btn {
      margin: 0 15px;
      padding: 12px 20px;
      font-weight: 700;
    }
  }

  &-desc {
    max-height: 100%;
    overflow-y: auto;
    margin: 0 0 8px 0;
    font-weight: 700;

    p:last-child {
      margin-bottom: 0;
    }
    // margin: 0 0 8px 0;
  }

  .cencel-link {
    padding: 0;
    margin-left: auto;
    width: 40px;
    color: #8aa2b2;
    max-height: 30px;
    margin-top: 10px;
    margin-right: 10px;
  }

  &.warning {
    .py-banner-title {
      &::after,
      &::before {
        background: #e65c00;
        /* fallback for old browsers */
        background: -webkit-linear-gradient(270deg, #f9d423, #e65c00);
        /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to left, #f9d423, #e65c00);
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      }
    }
  }

  strong {
    font-weight: 700;
  }
}

@keyframes scrollCardBg {
  0% {
    background-position: left 0 bottom 0;
  }

  100% {
    background-position: left 10% bottom 100%;
  }
}
